'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { OfferPageLayout } from '@/components';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { useLocale } from '@/hooks/useLocal';
import { Section, SectionDivider } from '@sal-solution/ui-web';
import { FC } from 'react';
import { homePageLocale } from '../HomePage/HomePage';

export const ContactPage: FC = () => {
  const locale = useLocale(homePageLocale);

  return (
    <OfferPageLayout>
      <SectionDivider title={locale.contactForm} id="contact" />
      <Section tw="px-8 pt-0">
        <ContactForm />
      </Section>
    </OfferPageLayout>
  );
};
