import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnCommentSendSubscriptionVariables = Types.Exact<{
  targetId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type OnCommentSendSubscription = { __typename?: 'Subscription', onCommentSend?: { __typename?: 'Payload', targetId?: string | null } | null };


export const OnCommentSendDocument = gql`
    subscription onCommentSend($targetId: ID) {
  onCommentSend(targetId: $targetId) {
    targetId
  }
}
    `;

/**
 * __useOnCommentSendSubscription__
 *
 * To run a query within a React component, call `useOnCommentSendSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommentSendSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommentSendSubscription({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useOnCommentSendSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCommentSendSubscription, OnCommentSendSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommentSendSubscription, OnCommentSendSubscriptionVariables>(OnCommentSendDocument, options);
      }
export type OnCommentSendSubscriptionHookResult = ReturnType<typeof useOnCommentSendSubscription>;
export type OnCommentSendSubscriptionResult = Apollo.SubscriptionResult<OnCommentSendSubscription>;