'use client';
import { OfferPageLayout } from '@/components';
import { Button, Form, Input, Section, Text } from '@sal-solution/ui-web';
import { useSearchParams } from 'next/navigation';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { useReplyToOfferRequestMutation } from './graphql/replyToOfferRequest.generated';
import Link from 'next/link';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/20/solid';
import { useFindOfferKitchenContractorQuery } from './graphql/findOfferKitchenContractor.generated';
import Image from 'next/image';
export const OfferReplyPage = () => {
  const params = useSearchParams();
  const offerId = params.get('offerId');
  const [reply, { data, loading, error }] = useReplyToOfferRequestMutation();
  const offerResponse = useFindOfferKitchenContractorQuery({
    variables: {
      findOfferKitchenContractorId: offerId,
    },
    skip: !offerId,
  });
  const to = params.get('to');
  const type = params.get('type');
  const location = params.get('location');
  const date = params.get('date');
  const amountOfCabinets = params.get('amountOfCabinets');
  const wrapTargets = params.get('wrapTargets');
  const expirationDate = params.get('expirationDate');
  // const color = params.get('color');
  if (!offerId || !to) {
    return (
      <OfferPageLayout>
        <Section>
          <Text>Invalid offer reply</Text>
        </Section>
      </OfferPageLayout>
    );
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    console.log('' + form.get('offer'));
    await reply({
      variables: {
        input: {
          offerId: offerId,
          contractorId: to,
          amount: '' + form.get('offer'),
        },
      },
    });
  };

  // const exDate = new Date(expirationDate || 0);

  if (data?.replyToOfferRequest) {
    return (
      <OfferPageLayout>
        <Section tw="flex-col justify-center space-y-4 px-4">
          {error && <Text tw="text-red-500">{error.message}</Text>}
          <CheckCircleIcon tw="w-16 h-16 mr-4 text-green-500" />

          <Text tw="text-green-500" variant="subTitle">
            Voorstel is verstuurd
          </Text>
          <Button
            disabled={!data?.replyToOfferRequest}
            variant="light"
            as={Link}
            href={`/offerte-comments?offerId=${offerId}&contractorId=${to}&responseId=${data?.replyToOfferRequest}&source=contractor`}
          >
            Chat met de aanvrager
          </Button>
        </Section>
      </OfferPageLayout>
    );
  }

  const offer = offerResponse.data?.findOfferKitchenContractor;
  const hasError = error || offerResponse.error;
  const isLoading = offerResponse.loading || loading;

  return (
    <OfferPageLayout>
      <Section tw="flex-col justify-start space-y-4 px-4">
        {error && <Text tw="text-red-500">{hasError?.message}</Text>}
        {isLoading && <Text>Loading...</Text>}
        {data?.replyToOfferRequest && (
          <Text tw="text-green-500" variant="subTitle">
            Voorstel is verstuurd
          </Text>
        )}
        <Text tw="w-full" variant="title">
          Offerte aanvraag{' '}
          {/* <span tw="font-medium text-xs italic">
            geldig tot: {exDate.toLocaleDateString()}
          </span> */}
        </Text>
        <Text tw="w-full">
          Plaats hieronder je prijsindicatie. Wij informeren de klant over je
          bod en nemen contact op bij akkoord.
        </Text>
        <div tw="grid grid-cols-3 gap-4 w-full rounded-md">
          {offer?.images?.map((image) => (
            <img
              key={image as string}
              src={image as string}
              width={150}
              height={150}
              alt="kitchen"
            />
          ))}
        </div>
        <div tw="px-4 py-4 bg-slate-100 rounded-md w-full">
          <Text>
            <span tw="font-bold">Locatie:</span> {offer?.town}
          </Text>
          {/* <Text>
            <span tw="font-bold">Gewenste datum:</span> {offer?.kitchenDate}
          </Text>
          <Text>
            <span tw="font-bold">Aantal kasten:</span> {offer?.kitchenSize}
          </Text> */}
          {offer?.wrapTargets && offer?.wrapTargets.length > 0 && (
            <Text>
              <span tw="font-bold">Wilt laten wrappen:</span>{' '}
              {offer?.wrapTargets?.join(',')}
            </Text>
          )}
          {/* <Text>
            <span tw="font-bold">Kleur:</span> {color}
          </Text> */}
        </div>
        <Form tw="mr-auto" onSubmit={onSubmit}>
          <Input label="Schatting offerte" name="offer" required />
          <Text tw="text-xs italic">
            Na akkoord van de klant ontvang je automatisch alle contactgegevens.
            Het delen/ontvangen van opmerkingen kan ondertussen ook via de
            chatfunctie.
          </Text>

          <div tw="flex gap-4 w-full mt-4">
            <Button
              disabled={!data?.replyToOfferRequest}
              variant="light"
              as={Link}
              href={`/offerte-comments?offerId=${offerId}&contractorId=${to}&responseId=${data?.replyToOfferRequest}&source=contractor`}
            >
              Chat met de aanvrager
            </Button>
            <Button disabled={loading}>
              {loading ? 'Loading' : 'Plaats een bod'}
            </Button>
          </div>
        </Form>
      </Section>
    </OfferPageLayout>
  );
};
