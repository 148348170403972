'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useLocale } from '../../hooks/useLocal';
import PrivacyNL from './PrivacyNL.mdx';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';
import { OfferPageLayout } from '@/components';
import { Section } from '@sal-solution/ui-web';

export const PrivacyPage: NextPage = () => {
  const Locale = useLocale({
    [localeNL]: PrivacyNL,
    [localeEN]: PrivacyNL,
  });

  return (
    <OfferPageLayout>
      <Section tw="px-4 lg:px-0">
        <div tw="prose lg:prose-xl">
          <Locale />
        </div>
      </Section>
    </OfferPageLayout>
  );
};
