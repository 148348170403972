'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { OfferPageLayout } from '@/components';
import { fetchFormOffer } from '@/fetch/fetchFormOffer';
import { useCurrentLocale, useLocale } from '@/hooks/useLocal';
import { localeEN, localeNL } from '@/utils/locale';
import { Section, SmartForm, SmartFormElement } from '@sal-solution/ui-web';
import { useRouter } from 'next/navigation';
import React, { FC } from 'react';
import {
  imageStepsEN,
  imageStepsNL,
  kitchenInformationStepsEN,
  kitchenInformationStepsNL,
  userInformationStepsEN,
  userInformationStepsNL,
} from './steps';
import { toast } from 'react-toastify';
import { sendGTMEvent } from '@next/third-parties/google';

const elementsNL: SmartFormElement[][] = [
  kitchenInformationStepsNL,
  //imageStepsNL,
  userInformationStepsNL,
];
const elementsEN: SmartFormElement[][] = [
  kitchenInformationStepsEN,
  //  imageStepsEN,
  userInformationStepsEN,
];

const elements = {
  [localeEN]: elementsEN,
  [localeNL]: elementsNL,
};

export const OfferPage: FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const locale = useLocale(elements);
  const currentLocale = useCurrentLocale();
  const router = useRouter();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    sendGTMEvent({ event: 'form_submit', value: 'offer_form' });
    setIsLoading(true);
    try {
      console.log('form press');
      e.preventDefault();
      const form = new FormData(e.currentTarget);

      if (form.get('kitchenImage1'))
        form.append('kitchenImages', form.get('kitchenImage1') as Blob);
      if (form.get('kitchenImage2'))
        form.append('kitchenImages', form.get('kitchenImage2') as Blob);
      if (form.get('kitchenImage3'))
        form.append('kitchenImages', form.get('kitchenImage3') as Blob);
      if (form.get('images')) {
        const images = form.getAll('images');
        images.forEach((image) => {
          form.append('kitchenImages', image as Blob);
        });
      }
      //
      form.delete('images');
      form.delete('kitchenImage1');
      form.delete('kitchenImage2');
      form.delete('kitchenImage3');

      const response = await fetchFormOffer(form);

      if (response?.message === 'Offer sent') {
        router.push('/bevestiging');
      } else {
        toast.error('Er is iets misgegaan, probeer het later opnieuw');
      }
    } catch (error) {
      console.warn(error);
      toast.error('Er is iets misgegaan, probeer het later opnieuw');
    }
    setIsLoading(false);
  };

  const onNextStepValidation = (step: number) => {
    console.log('step', 2);
    if (step === 0 || step === 1 || step === 2) {
      const checkMarksTargets = kitchenInformationStepsNL[0];

      if (checkMarksTargets.type === 'checkmark') {
        const checkedTargets = checkMarksTargets.checkMarks.map(({ name }) => {
          const target: HTMLInputElement | null = document.querySelector(
            `input[name="${name}"]`
          );
          return target?.checked;
        });

        if (!checkedTargets.some((checked) => checked)) {
          throw new Error('Selecteer minimaal één optie op te wrappen');
        }
      }

      const checkMarksDates = kitchenInformationStepsNL[1];
      if (checkMarksDates.type === 'checkmark') {
        const checkedDates = checkMarksDates.checkMarks.map(({ name }) => {
          const date: HTMLInputElement | null = document.querySelector(
            `input[name="${name}"]`
          );
          return date?.checked;
        });

        if (!checkedDates.some((checked) => checked)) {
          throw new Error('Selecteer minimaal één datum');
        }
      }
    }
  };

  return (
    <OfferPageLayout>
      <Section tw="px-8 items-start">
        <SmartForm
          tw="flex-1 lg:flex-none"
          submitText={currentLocale === localeEN ? 'Submit' : 'Verstuur'}
          nextText={currentLocale === localeEN ? 'Next' : 'Volgende'}
          previousText={currentLocale === localeEN ? 'Previous' : 'Vorige'}
          loading={isLoading}
          elements={locale}
          onSubmit={onSubmit}
          extraValidation={onNextStepValidation}
        />
      </Section>
    </OfferPageLayout>
  );
};
