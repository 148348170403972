import wrap1 from './assets/wrap-1.jpg';
import wrap2 from './assets/wrap-2.jpg';
import wrap3 from './assets/wrap-3.jpg';
import wrap4 from './assets/wrap-4.jpg';
import wrap5 from './assets/wrap-5.jpg';
import wrap6 from './assets/wrap-6.jpg';
import wrap7 from './assets/wrap-7.jpg';
import wrap8 from './assets/wrap-8.jpg';

export const carouselImages = [
  // wrap1.src,
  wrap3.src,
  wrap2.src,
  wrap4.src,
  wrap5.src,
  wrap6.src,
  wrap7.src,
  wrap8.src,
];
