'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { fetchFormOffer } from '@/fetch/fetchFormOffer';
import { useCurrentLocale, useLocale } from '@/hooks/useLocal';
import {
  kitchenInformationStepsEN,
  kitchenInformationStepsNL,
} from '@/modules/OfferPage/steps';
import { sendGTMEvent } from '@next/third-parties/google';
import {
  Checkmark,
  SmartFormElementCheckMark,
  Text,
  UploadUserInformation,
} from '@sal-solution/ui-web';
import { localeEN, localeNL } from '@sal-solution/utils';
import { useRouter } from 'next/navigation';
import React, { ComponentProps, FC } from 'react';
import { toast } from 'react-toastify';
import { pixelEvent } from '@/components';

type Props = {
  includeCheckMarks?: boolean;
  checkMarkVariant?: ComponentProps<typeof Checkmark>['variant'];
};

export const LeadForm: FC<Props> = ({
  includeCheckMarks = true,
  checkMarkVariant = 'light',
}) => {
  const locale = useLocale({
    [localeEN]: kitchenInformationStepsEN[0] as SmartFormElementCheckMark,
    [localeNL]: kitchenInformationStepsNL[0] as SmartFormElementCheckMark,
  });

  const currentLocale = useCurrentLocale();

  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    sendGTMEvent({ event: 'form_submit', value: 'offer_form' });
    setIsLoading(true);
    try {
      console.log('form press');
      e.preventDefault();
      const form = new FormData(e.currentTarget);

      if (form.get('kitchenImage1'))
        form.append('kitchenImages', form.get('kitchenImage1') as Blob);
      if (form.get('kitchenImage2'))
        form.append('kitchenImages', form.get('kitchenImage2') as Blob);
      if (form.get('kitchenImage3'))
        form.append('kitchenImages', form.get('kitchenImage3') as Blob);
      if (form.get('images')) {
        const images = form.getAll('images');
        images.forEach((image) => {
          form.append('kitchenImages', image as Blob);
        });
      }
      //
      form.delete('images');
      form.delete('kitchenImage1');
      form.delete('kitchenImage2');
      form.delete('kitchenImage3');

      const response = await fetchFormOffer(form);

      if (response?.message === 'Offer sent') {
        try {
          pixelEvent('Lead', {
            content_name: 'Offer',
            content_category: 'Offer',
            content_email: form.get('email') as string,
          });
        } catch (error) {
          console.warn(error);
        }
        window.location.href = '/bevestiging';
      } else {
        toast.error('Er is iets misgegaan, probeer het later opnieuw');
      }
    } catch (error) {
      console.warn(error);
      toast.error('Er is iets misgegaan, probeer het later opnieuw');
    }
    setIsLoading(false);
  };

  return (
    <UploadUserInformation
      isLoading={isLoading}
      onSubmit={onSubmit}
      locale={currentLocale}
    >
      {includeCheckMarks && (
        <Text variant="subTitle" tw="w-full text-base text-left">
          {locale?.label}
        </Text>
      )}
      {includeCheckMarks &&
        locale?.checkMarks.map((step, index) => (
          <Checkmark
            variant={checkMarkVariant}
            key={index}
            label={step.label}
            name={step.name as string}
          />
        ))}
    </UploadUserInformation>
  );
};
