'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { Section } from '@sal-solution/ui-web';
import { Reviews as UIReviews } from '@/components/Reviews/Reviews';

export const Reviews = () => {
  return (
    <Section tw="overflow-hidden">
      <UIReviews />
    </Section>
  );
};
