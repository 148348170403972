'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { OfferPageLayout } from '@/components';
import { Button, Section, SmartForm, Text } from '@sal-solution/ui-web';
import Link from 'next/link';
import React, { Fragment, useState } from 'react';
import { useFindContractorRepliesWithPasswordQuery } from './graphql/findContractorRepliesWithPassword.generated';
import { toCurrency } from '@sal-solution/utils';
import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  EyeIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

export const OfferOverviewPage = () => {
  const [showNonAccepted, setShowNonAccepted] = useState(false);
  const searchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window?.location?.search)
      : undefined;
  const id = searchParams?.get('contractorId');
  const [password, setPassword] = useState<string>();

  const { data, loading, error } = useFindContractorRepliesWithPasswordQuery({
    variables: { findContractorRepliesWithPasswordId: id, password },
    skip: !password,
  });
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const password = formData.get('password') as string;

    setPassword(password);
  };

  const signedIn = !!data?.findContractorRepliesWithPassword;

  if (!id)
    return (
      <OfferPageLayout>
        <Section tw="px-8 items-start justify-start flex-col space-y-4">
          <Text variant="subTitle">Geen geldige ID gevonden</Text>
          <Button as={Link} href="/">
            Terug naar het begin scherm
          </Button>
        </Section>
      </OfferPageLayout>
    );

  if (!signedIn)
    return (
      <OfferPageLayout>
        <Section tw="px-8 items-start justify-start flex-col space-y-4">
          {error && <Text variant="subTitle">{error.message}</Text>}
          <Text variant="subTitle">
            Je moet ingelogd zijn om deze pagina te bekijken.
          </Text>
          <SmartForm
            submitText="Login"
            elements={[
              [
                {
                  type: 'input',
                  name: 'password',
                  label: 'Password',
                  required: true,
                  placeholder: 'Password',
                  inputType: 'password',
                },
              ],
            ]}
            loading={loading}
            onSubmit={onSubmit}
          />
        </Section>
      </OfferPageLayout>
    );

  const contractResponses =
    data?.findContractorRepliesWithPassword
      ?.map((offer) =>
        offer?.responses?.map((response) => {
          return {
            offerId: offer?._id,
            name: offer?.name,
            email: offer?.email,
            phone: offer?.phone,
            town: offer?.town,
            quoteNumber: offer?.quoteNumber,
            created_at: offer?.created_at,
            images: offer?.images,
            ...response,
          };
        })
      )
      .flat() || [];

  const filteredOffers = contractResponses.filter(
    (offer) => showNonAccepted || offer?.accepted
  );

  return (
    <OfferPageLayout>
      <Section tw="px-8 items-start justify-start flex-col">
        {error && <Text variant="error">Er is iets fout gegaan</Text>}
        <Button
          tw="mb-4 sticky top-24 z-20"
          onClick={() => setShowNonAccepted(!showNonAccepted)}
          variant="info"
        >
          {showNonAccepted ? (
            <>
              <CheckIcon tw="w-4 h-4" />
              <Text tw="ml-2">Toon geaccepteerde offertes</Text>
            </>
          ) : (
            <>
              <XCircleIcon tw="w-4 h-4" />
              <Text tw="ml-2">Toon alle offertes</Text>
            </>
          )}
        </Button>
        {filteredOffers.map((offer) => (
          <Fragment key={offer?.offerId}>
            <div key={offer?._id}>
              <Text variant="subTitle">{offer?.quoteNumber}</Text>
              {offer?.created_at && (
                <Text tw="italic">
                  {new Date(Number(offer?.created_at)).toLocaleDateString()}
                </Text>
              )}
              <Text>
                <b>Naam:</b> {offer?.name}
              </Text>
              <Text>
                <b>Email:</b> {offer?.email}
              </Text>
              <Text>
                <b>Tel:</b> {offer?.phone}
              </Text>
              <Text>
                <b>Stad:</b> {offer?.town}
              </Text>

              <Text tw="flex items-center">
                <b>Status:</b>{' '}
                {offer?.accepted ? (
                  <>
                    {' '}
                    Geaccepteerd
                    <CheckCircleIcon tw="w-4 h-4 text-green-500" />
                  </>
                ) : (
                  <>
                    {' '}
                    wachten <ClockIcon tw="w-4 h-4 text-orange-500" />
                  </>
                )}
              </Text>
              <Text>Bod: {toCurrency(offer?.amount || 0)}</Text>
              <div tw="grid grid-cols-3 gap-3">
                {offer?.images?.map((image) => (
                  <img
                    tw="w-full max-w-[150px]"
                    key={image}
                    src={image as string}
                    alt="offer"
                  />
                ))}
              </div>
              <div tw="space-x-2 pt-2">
                <Button
                  as={Link}
                  href={`/offer?type=response&offerId=${offer?.offerId}&to=${id}`}
                  target="_blank"
                  variant="info"
                >
                  <EyeIcon tw="w-4 h-4" />
                </Button>
                <Button
                  tw="relative"
                  as={Link}
                  href={`/offerte-comments?offerId=${offer?.offerId}&responseId=${offer?._id}&contractorId=${id}&source=contractor`}
                >
                  {offer?.comments ? (
                    <div
                      tw="badge badge-accent absolute -top-2 -right-4"
                      className="badge badge-primary"
                    >
                      {offer?.comments.length}
                    </div>
                  ) : (
                    <></>
                  )}
                  <ChatBubbleLeftIcon tw="w-4 h-4" />
                </Button>
              </div>
            </div>
            <div tw="divider"></div>
          </Fragment>
        ))}
      </Section>
    </OfferPageLayout>
  );
};
