export const fetchUploadOffer = async (body: FormData) => {
  try {
    const response = await fetch(
      `${process.env.BE_URL}/api/offer/images-upload`,
      {
        method: 'POST',
        headers: {
          Accept: 'multipart/form-data',
        },
        body,
      }
    );
    const data = await response.json();
    console.log('data', data);
    return data;
  } catch (error) {
    console.error(error);
  }
};
