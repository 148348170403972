import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "next-mdx-import-source-file";
function _createMdxContent(props) {
  const _components = {
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Cookies en Website Functionaliteit"
      })
    }), "\n", _jsx(_components.p, {
      children: "Onze website gebruikt cookies. Dit zijn kleine informatiebestanden die op uw apparaat worden opgeslagen wanneer u onze site bezoekt. Ze helpen bij het onthouden van uw instellingen en voorkeuren en zijn essentieel voor de juiste werking van de website, zoals het inschakelen van basisfuncties en het ondersteunen van derde partijen die ons van content voorzien. Het gebruik van cookies op onze site is volkomen veilig."
    }), "\n", _jsx(_components.p, {
      children: "Essentiële Cookies en Website Analyse"
    }), "\n", _jsx(_components.p, {
      children: "We beperken ons tot het gebruik van essentiële cookies die noodzakelijk zijn voor het functioneren van onze website. Deze cookies zorgen ervoor dat de website naar behoren werkt. Daarnaast gebruiken we Google Analytics, een tool die ons helpt om de site te analyseren en te verbeteren. Met behulp van Google Analytics kunnen we onder meer de meest bezochte pagina's identificeren, oorzaken van trage laadtijden ontdekken en andere kritische aspecten evalueren die bijdragen aan een betere gebruikerservaring."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
