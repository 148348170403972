import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReplyToOfferRequestMutationVariables = Types.Exact<{
  input: Types.ReplyInput;
}>;


export type ReplyToOfferRequestMutation = { __typename?: 'Mutation', replyToOfferRequest: string };


export const ReplyToOfferRequestDocument = gql`
    mutation replyToOfferRequest($input: ReplyInput!) {
  replyToOfferRequest(input: $input)
}
    `;
export type ReplyToOfferRequestMutationFn = Apollo.MutationFunction<ReplyToOfferRequestMutation, ReplyToOfferRequestMutationVariables>;

/**
 * __useReplyToOfferRequestMutation__
 *
 * To run a mutation, you first call `useReplyToOfferRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToOfferRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToOfferRequestMutation, { data, loading, error }] = useReplyToOfferRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplyToOfferRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReplyToOfferRequestMutation, ReplyToOfferRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyToOfferRequestMutation, ReplyToOfferRequestMutationVariables>(ReplyToOfferRequestDocument, options);
      }
export type ReplyToOfferRequestMutationHookResult = ReturnType<typeof useReplyToOfferRequestMutation>;
export type ReplyToOfferRequestMutationResult = Apollo.MutationResult<ReplyToOfferRequestMutation>;
export type ReplyToOfferRequestMutationOptions = Apollo.BaseMutationOptions<ReplyToOfferRequestMutation, ReplyToOfferRequestMutationVariables>;