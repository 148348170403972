'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import { useFindOfferResponseCommentsQuery } from './graphql/findOfferResponseComments.generated';
import { useCommentOfferKitchenResponseMutation } from './graphql/commentOfferKitchenResponse.generated';
import { InputMaybe, OfferResponseInput } from '@sal-solution/types';
import { OfferPageLayout } from '@/components';
import {
  Button,
  ChatBubble,
  Section,
  SmartForm,
  SmartFormElement,
  Text,
} from '@sal-solution/ui-web';
import { useOnCommentSendSubscription } from '../graphql/onCommentSend.generated';

export const OfferCommentsPage = () => {
  const params = useSearchParams();
  const offerId = params.get('offerId');
  const responseId = params.get('responseId');
  const contractorId = params.get('contractorId');
  const source = params.get('source');

  const { data, loading, error, refetch } = useFindOfferResponseCommentsQuery({
    variables: {
      offerId,
      responseId,
      contractorId,
    },
  });

  const {
    data: commentsData,
    loading: commentsLoading,
    error: commentsError,
  } = useOnCommentSendSubscription({
    variables: {
      targetId: responseId as string,
    },
    skip: !responseId,
  });

  const [createComment, response] = useCommentOfferKitchenResponseMutation();
  const onRefetch = async () => {
    await refetch();
  };

  useEffect(() => {
    onRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const comment = form.get('comment') as string;

    if (!comment) return;
    if (!offerId) return;
    if (!responseId) return;

    const input: InputMaybe<OfferResponseInput> = {
      comment,
      offerKitchenId: offerId,
      responseId,
    };
    if (source === 'contractor' && contractorId) {
      input.contractorId = contractorId;
    }

    await createComment({
      variables: {
        input,
      },
    });
    await onRefetch();
  };

  const isLoading = loading || response.loading;
  const isError = error || response.error || commentsError;

  const comments = data?.findOfferResponseComments;
  return (
    <OfferPageLayout>
      <Section tw="flex-col justify-start items-start space-y-4 px-4">
        {isError && <div tw="text-red-500">{isError.message}</div>}
        {isLoading && <div tw="text-blue-500">Loading...</div>}
        <div tw="flex w-full justify-between">
          <Text variant="title">Comments</Text>
          <Button onClick={onRefetch}>Verversen</Button>
        </div>
        <div tw="flex flex-col items-start w-full justify-between my-4 space-y-4 h-full max-h-[40vh] overflow-y-scroll bg-slate-100 rounded-md p-4">
          {comments && comments?.length > 0 ? (
            comments
              ?.map((comment) => (
                <ChatBubble
                  key={comment?._id}
                  isSender={comment?.sender === source}
                  sender={comment?.sender}
                >
                  {comment?.comment}
                </ChatBubble>
              ))
              .reverse()
          ) : (
            <ChatBubble sender="Nog geen comments" timeStamp="Zo juist">
              ...
            </ChatBubble>
          )}
        </div>
        <SmartForm tw="w-full" onSubmit={onSubmit} elements={[elements]} />
      </Section>
    </OfferPageLayout>
  );
};

const elements: SmartFormElement[] = [
  {
    label: 'Comment',
    name: 'comment',
    type: 'input',
    required: true,
    placeholder: 'Comment',
  },
];
