import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindContractorRepliesWithPasswordQueryVariables = Types.Exact<{
  findContractorRepliesWithPasswordId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  password?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FindContractorRepliesWithPasswordQuery = { __typename?: 'Query', findContractorRepliesWithPassword?: Array<{ __typename?: 'OfferKitchen', _id: string, quoteNumber: string, name: string, town: string, email: string, phone: string, images?: Array<string | null> | null, created_at: string, responses?: Array<{ __typename?: 'OfferResponse', accepted?: boolean | null, amount: number, _id: string, comments?: Array<{ __typename?: 'OfferComment', _id: string } | null> | null } | null> | null } | null> | null };


export const FindContractorRepliesWithPasswordDocument = gql`
    query findContractorRepliesWithPassword($findContractorRepliesWithPasswordId: ID, $password: String) {
  findContractorRepliesWithPassword(
    id: $findContractorRepliesWithPasswordId
    password: $password
  ) {
    _id
    quoteNumber
    responses {
      accepted
      amount
      _id
      comments {
        _id
      }
    }
    name
    town
    email
    phone
    images
    created_at
  }
}
    `;

/**
 * __useFindContractorRepliesWithPasswordQuery__
 *
 * To run a query within a React component, call `useFindContractorRepliesWithPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContractorRepliesWithPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContractorRepliesWithPasswordQuery({
 *   variables: {
 *      findContractorRepliesWithPasswordId: // value for 'findContractorRepliesWithPasswordId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useFindContractorRepliesWithPasswordQuery(baseOptions?: Apollo.QueryHookOptions<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>(FindContractorRepliesWithPasswordDocument, options);
      }
export function useFindContractorRepliesWithPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>(FindContractorRepliesWithPasswordDocument, options);
        }
export function useFindContractorRepliesWithPasswordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>(FindContractorRepliesWithPasswordDocument, options);
        }
export type FindContractorRepliesWithPasswordQueryHookResult = ReturnType<typeof useFindContractorRepliesWithPasswordQuery>;
export type FindContractorRepliesWithPasswordLazyQueryHookResult = ReturnType<typeof useFindContractorRepliesWithPasswordLazyQuery>;
export type FindContractorRepliesWithPasswordSuspenseQueryHookResult = ReturnType<typeof useFindContractorRepliesWithPasswordSuspenseQuery>;
export type FindContractorRepliesWithPasswordQueryResult = Apollo.QueryResult<FindContractorRepliesWithPasswordQuery, FindContractorRepliesWithPasswordQueryVariables>;