'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useLocale } from '@/hooks/useLocal';
import { Section, Text, Carousel as UICarousel } from '@sal-solution/ui-web';
import React, { FC } from 'react';
import { homePageLocale } from '../HomePage';
import { carouselImages } from '../carouselImages';

export const Carousel: FC = () => {
  const locale = useLocale(homePageLocale);

  return (
    <Section tw="px-8 flex-col lg:(flex-row gap-4 grid grid-cols-2)">
      <Text variant="title" tw="text-3xl mb-4 lg:(text-6xl)">
        {locale.resultsCarousel}
      </Text>
      <UICarousel images={carouselImages} />
    </Section>
  );
};
