/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useLocale } from '@/hooks/useLocal';
import {
  Button,
  Diff,
  DiffCustom,
  HeroSection,
  Text,
} from '@sal-solution/ui-web';
import Link from 'next/link';
import { FC } from 'react';
import { homePageLocale } from '../HomePage';
import afterImg from '../assets/after.jpeg';
import beforeImg from '../assets/before.jpeg';
import tw from 'twin.macro';

import { sendGAEvent } from '@next/third-parties/google';

export const Compare: FC = () => {
  const locale = useLocale(homePageLocale);

  const onNavigate = () => {
    sendGAEvent({
      event: 'go_to_form_1',
      action: 'click',
      category: 'form',
    });
  };

  return (
    <HeroSection
      id="droom-keuken"
      // topVariant="Curved"
      bottomVariant="Curved"
      tw="bg-slate-200 lg:min-h-[60vh] "
      bottomContentsStyle={[]}
      innerStyle={[tw`px-8 flex-col lg:(flex-row-reverse gap-4) 2xl:(pb-0)`]}
    >
      <div tw="lg:(ml-auto)">
        <Text variant="title" tw="text-3xl mb-4 lg:(text-6xl)">
          {locale.resultDesc}
        </Text>
        <Button
          tw="hidden lg:(inline-flex flex-none mb-0)"
          as={Link}
          href="/#offerte-aanvragen"
        >
          {locale.button}
        </Button>
      </div>
      <Diff imageLeft={afterImg.src} imageRight={beforeImg.src} />
      <DiffCustom imageLeft={afterImg.src} imageRight={beforeImg.src} />
      <Button
        tw="mt-8 w-full lg:(hidden)"
        onClick={onNavigate}
        as={Link}
        href="/#offerte-aanvragen"
      >
        {locale.button}
      </Button>
    </HeroSection>
  );
};
