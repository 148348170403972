'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { OfferPageLayout } from '@/components';
import * as Icon from '@heroicons/react/24/outline';
import { Section, Text } from '@sal-solution/ui-web';
import { FC } from 'react';

export type Props = {
  title?: string;
  description?: string;
};

export const ConfirmationPage: FC<Props> = ({
  title = 'Uw aanvraag is ontvangen!',
  description = ' Wij hebben uw aanvraag ontvangen en zullen deze zo snel mogelijk verwerken.',
}) => {
  return (
    <OfferPageLayout>
      <Section tw="px-8 flex-col items-center justify-center">
        <Icon.CheckCircleIcon tw="w-24 h-24 text-green-500 " />
        <Text tw="text-center" variant="title">
          {title}
        </Text>
        <Text tw="text-center">{description}</Text>
      </Section>
      <script
        dangerouslySetInnerHTML={{
          __html: `
         gtag('event', 'conversion', {
          'send_to': 'AW-16583139963/XvGUCJeRiLkZEPvEuuM9'
         });
         `,
        }}
      ></script>
    </OfferPageLayout>
  );
};
