'use client';
import { useLocale } from '@/hooks/useLocal';
/** @jsxImportSource @emotion/react */

import {
  Button,
  HeroSection,
  StepWithHighlight,
  highLightColors,
  pickRandomColor,
} from '@sal-solution/ui-web';
import React, { FC } from 'react';
import tw from 'twin.macro';
import { homePageLocale } from '../HomePage';
import Link from 'next/link';
import { sendGAEvent } from '@next/third-parties/google';

export const Steps: FC = () => {
  const locale = useLocale(homePageLocale);

  const onNavigate = () => {
    sendGAEvent({
      event: 'go_to_form_2',
      action: 'click',
      category: 'form',
    });
  };

  return (
    <HeroSection
      topVariant="Curved"
      bottomVariant="Curved"
      contentsStyle={[tw`bg-purple-50`]}
    >
      <div tw="flex flex-col">
        <div tw="grid grid-cols-1 gap-4 md:grid-cols-3 w-full px-8 ">
          {locale.howItWorks.steps.map((step, index) => (
            <StepWithHighlight
              key={index}
              SpanColor={highLightColors[index] || pickRandomColor()}
              {...step}
            />
          ))}
        </div>
        <div tw="flex w-full justify-center px-8">
          <Button
            tw="mt-8 flex-1 lg:(flex-none)"
            as={Link}
            onClick={onNavigate}
            href="/#offerte-aanvragen"
          >
            {locale.button}
          </Button>
        </div>
      </div>
    </HeroSection>
  );
};
