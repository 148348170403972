'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { OfferPageLayout } from '@/components';
import { Button, FileUpload, Form, Section, Text } from '@sal-solution/ui-web';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { fetchUploadOffer } from '@/fetch/fetchUploadOffer';
import { toast } from 'react-toastify';

export const OfferUploadImagePage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();
  const params = useSearchParams();
  const offerId = params.get('offerId');
  const token = params.get('token');

  const onUploadImages = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const form = new FormData(event.currentTarget);
    const images = form.getAll('images');
    if (!offerId || !token || images.length === 0) {
      toast.error('Please upload images');
      setIsLoading(false);
      return;
    }
    form.append('offerId', offerId);
    form.append('token', token);
    const repsonse = await fetchUploadOffer(form);
    if (repsonse.message === 'File upload') router.push('/bevestiging');
    else
      toast.error(
        'Er ging iets mis! Probeer het opnieuw of neem contact met ons op.'
      );
    setIsLoading(false);
  };

  return (
    <OfferPageLayout>
      <Section tw="flex flex-col px-4 justify-start items-start space-y-4">
        {isLoading && <Text>Loading...</Text>}
        <Text variant="title">Upload een afbeelding van je keuken</Text>
        <Text>
          Upload hier een afbeelding van je keuken om een betere offerte te
          ontvangen.
        </Text>
        <div>
          <Form tw="flex flex-row space-x-4" onSubmit={onUploadImages}>
            <FileUpload
              uploadText="Upload je afbeeldingen"
              name="images"
              placeholder="Upload je afbeeldingen"
            />
            <Button variant="success" type="submit">
              Verstuur
            </Button>
          </Form>
        </div>
      </Section>
    </OfferPageLayout>
  );
};
