'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { SectionDivider, Section, Collapse } from '@sal-solution/ui-web';
import React, { FC } from 'react';
import { homePageLocale } from '../HomePage';
import { useLocale } from '@/hooks/useLocal';

export const FAQ: FC = () => {
  const locale = useLocale(homePageLocale);

  return (
    <>
      <SectionDivider title={locale.faqTitle} id="faq" />
      <Section tw="grid grid-cols-1 gap-y-4 px-8">
        {locale.faq.map((faq, index) => (
          <Collapse key={index} title={faq.question}>
            <p>{faq.answer}</p>
          </Collapse>
        ))}
      </Section>
    </>
  );
};
