'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { OfferPageLayout } from '@/components';
import { localeEN, localeNL } from '@sal-solution/utils';
import { FC } from 'react';
import { Carousel } from './sections/Carousel';
import { Compare } from './sections/Compare';
import { FAQ } from './sections/FAQ';
import { Reviews } from './sections/Reviews';
import { Steps } from './sections/Steps';
import { Hero } from './sections/Hero';
import { QuickForm } from './sections/QuickForm';

export const HomePage: FC = () => {
  return (
    <OfferPageLayout>
      <Hero />
      <Compare />
      <Carousel />
      <Steps />
      <QuickForm />
      <Reviews />
      <FAQ />
    </OfferPageLayout>
  );
};

export const homePageLocale = {
  [localeEN]: {
    title: 'We find the best kitchen wrapper for you!',
    subtitle:
      'We contact the contractors and make sure you get the best quotes.',
    button: 'I want to know more!',
    contactForm: 'Any questions? Contact us!',
    texts: ['Best', 'Fastest', 'Cheapest'],
    textTop: 'We find the',
    textBottom: 'kitchen wrapper for you!',
    results: 'Our results',
    resultDesc: 'Give your kitchen a new look!',
    resultsCarousel: 'Choose from 100+ colors and patterns!',
    howItWorks: {
      title: 'How it works',
      steps: [
        {
          countText: '1.',
          title: 'Fill in the form',
          description: 'Fill in the form and we will contact you.',
          highlightedText: 'form',
        },
        {
          countText: '2.',
          title: 'We find the best kitchen wrapper',
          description:
            'We contact the contractors and make sure you get the best quotes.',
          highlightedText: 'best',
        },
        {
          countText: '3.',
          title: 'You receive the quotes',
          description: 'You receive the quotes and can choose the best one.',
          highlightedText: 'quotes',
        },
      ],
    },
    faqTitle: 'Frequently asked questions',
    faq: [
      {
        question: 'Is wrapping your kitchen cost-effective?',
        answer:
          'Kitchen wrapping is often much cheaper than a complete kitchen renovation or replacing kitchen cabinets.',
      },
      {
        question: 'How long does it take to wrap a kitchen?',
        answer: 'Wrapping a kitchen can be done quickly, usually within a day.',
      },
      {
        question: 'What options do I have for wrapping my kitchen?',
        answer:
          'There is a wide range of colors, textures, and finishes available, allowing you to customize your kitchen to your taste.',
      },
      {
        question: 'Is wrapping a kitchen sustainable?',
        answer:
          'Kitchen wrapping extends the life of your existing kitchen cabinets, which means less waste.',
      },
      {
        question: 'How easy is it to maintain a wrapped kitchen?',
        answer: 'The wrap is easy to clean and maintain.',
      },
      {
        question: 'Can I adjust a wrapped kitchen later?',
        answer:
          'Should you ever want to change style, the wrap can be easily removed or replaced without damaging your cabinets.',
      },
      {
        question: 'How long does a wrapped kitchen last?',
        answer:
          'While durable, kitchen wraps do not last forever and can show wear over time.',
      },
    ],
  },
  [localeNL]: {
    title: 'Wij vinden de beste keukenwrapper voor jou!',
    subtitle:
      'Wij nemen contact op met de aannemers en zorgen ervoor dat jij de beste offertes ontvangt.',
    button: 'Gratis offerte aanvragen',
    contactForm: 'Vragen? Neem contact op!',
    texts: ['Beste', 'Snelste', 'Goedkoopste'],
    textTop: 'Vind hier de',
    textBottom: 'Keukenwrapper voor jou!',
    results: 'Onze resultaten',
    resultDesc: 'Geef jouw droomkeuken een nieuwe look!',
    resultsCarousel: 'Keuze uit 100+ kleuren en patronen!',
    howItWorks: {
      title: 'Hoe werkt het',
      steps: [
        {
          countText: '1.',
          title: 'Vul het formulier in',
          description: 'Vul het formulier in en wij nemen contact met je op.',
          highlightedText: 'formulier',
        },
        {
          countText: '2.',
          title: 'Wij vinden de beste keukenwrapper',
          description:
            'Wij nemen contact op met de aannemers en zorgen ervoor dat jij de beste offertes ontvangt.',
          highlightedText: 'beste',
        },
        {
          countText: '3.',
          title: 'Je ontvangt de offertes',
          description: 'Je ontvangt de offertes en kunt de beste kiezen.',
          highlightedText: 'offertes',
        },
      ],
    },
    faqTitle: 'Veelgestelde vragen',
    faq: [
      {
        question: 'Is je keuken laten wrappen kostenbesparend?',
        answer:
          'Keukenwrappen is vaak stukken goedkoper dan een complete keukenrenovatie of het vervangen van keukenkastjes.',
      },
      {
        question: 'Hoe lang duurt het om een keuken te wrappen?',
        answer:
          'Het wrappen van een keuken kan snel worden uitgevoerd, meestal binnen een dag.',
      },
      {
        question: 'Welke opties heb ik voor het wrappen van mijn keuken?',
        answer:
          'Er is een breed scala aan kleuren, texturen en afwerkingen beschikbaar, waardoor je je keuken volledig naar jouw smaak kunt aanpassen.',
      },
      {
        question: 'Is een keuken wrappen duurzaam?',
        answer:
          'Keukenwrappen verlengt de levensduur van je bestaande keukenkastjes, wat minder afval betekent.',
      },
      {
        question: 'Hoe eenvoudig is het om een gewrapte keuken te onderhouden?',
        answer: 'De wrap is gemakkelijk schoon te maken en te onderhouden.',
      },
      {
        answer:
          'Mocht je ooit van stijl willen veranderen, dan kan de wrap eenvoudig worden verwijderd of vervangen zonder je kastjes te beschadigen.',
        question: 'Kan ik een gewrapte keuken later nog aanpassen?',
      },
      {
        answer:
          'Hoewel duurzaam, gaan keukenwraps niet eeuwig mee en kunnen ze na verloop van tijd slijtage vertonen.',
        question: 'Hoe lang gaat een gewrapte keuken mee?',
      },
    ],
  },
};
