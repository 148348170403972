import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/ConfirmationPage/ConfirmationPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/ContactPage/ContactPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/HomePage/HomePage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferCommentsPage/OfferCommentsPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferOverviewPage/OfferOverviewPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferPage/OfferPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferRepliesPage/OfferRepliesPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferReplyPage/OfferReplyPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/OfferUploadImagePage/OfferUploadImagePage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/PrivacyPage/PrivacyPage.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/apps/offer-next/modules/TermsPage/TermsPage.tsx");
