'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { useLocale } from '@/hooks/useLocal';
import {
  GoDownButton,
  HeroSection,
  Text,
  WriteTexts,
} from '@sal-solution/ui-web';
import { FC } from 'react';
import tw from 'twin.macro';
import { homePageLocale } from '../HomePage';
import { LeadForm } from './components/LeadForm/LeadForm';
import bgImage from '../assets/bg-2-lg.jpg';

const CircleImage = tw`after:([content: ''] lg:(w-96 h-96) w-64 h-64 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 [background-image: url('/assets/icons/icon.png')] bg-contain bg-center)`;
const CircleBackDrop = tw`before:([content: ''] lg:(w-[300px] h-[300px]) absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-orange-200 w-[200px] h-[200px] rounded-full)`;
const Circle = tw.div`relative w-[300px] h-[300px]`;

export const Hero: FC = () => {
  const locale = useLocale(homePageLocale);

  return (
    <HeroSection
      bgImage={`url(${bgImage.src})`}
      tw="lg:min-h-[60vh] px-0"
      bottomContentsStyle={[]}
      innerStyle={[
        tw` flex flex-col items-start justify-start pt-0 px-8 text-white lg:(flex-row items-center pt-12 pb-24)`,
      ]}
    >
      {/* <Circle tw="lg:(ml-auto mr-auto)" css={[CircleBackDrop, CircleImage]} /> */}
      <div tw="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-blue-800 to-blue-100 opacity-60"></div>
      <div tw="lg:(max-w-xl) mb-4 relative z-10">
        <Text variant="title" tw="text-4xl mt-28 lg:(text-6xl mt-8 )">
          {locale.textTop}
        </Text>
        <WriteTexts texts={locale.texts} tw="lg:(text-6xl)" />
        <Text variant="title" tw="text-4xl lg:(text-6xl) ">
          {locale.textBottom}
        </Text>
      </div>
      <div tw="p-4 backdrop-blur-sm bg-white/30 rounded-md relative z-10 mt-8">
        <LeadForm includeCheckMarks />
      </div>

      <GoDownButton
        href="/#droom-keuken"
        tw="mx-auto mt-4 border-gray-100 text-gray-100 relative z-10 lg:hidden"
      />
    </HeroSection>
  );
};
