/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { Review, Slider } from '@sal-solution/ui-web';
import { ComponentProps, FC } from 'react';

export const Reviews: FC = () => {
  return (
    <Slider
      tw="h-full"
      slides={reviews.map((item, i) => (
        <Review key={i} {...item} />
      ))}
    />
  );
};

type reviewProps = ComponentProps<typeof Review>;

const reviews: reviewProps[] = [
  {
    rating: 4,
    person: 'S',
    quote:
      'Keukenwrap Vergelijker heeft me echt geholpen. Ik ontving snel meerdere offertes en vond een topbedrijf dat mijn keuken prachtig heeft gewrapped. Dank!',
  },
  {
    rating: 5,
    person: 'L',
    quote:
      'Ik was verbaasd hoe makkelijk het was. Na het weekend ontving ik de beste deal en een week later was mijn aanrecht al gewrapped. Echt een aanrader!',
  },
  {
    rating: 5,
    person: 'G',
    quote:
      'Onze keuken was nog in goede staat, dus we wilden geen nieuwe kopen. Uiteindelijk een bedrijf gevonden dat onze keuken kon wrappen. Het resultaat is geweldig, we zijn superblij!',
  },
  {
    rating: 5,
    person: 'I',
    quote:
      'Een betrouwbare wrap specialist gevonden die op korte termijn tijd had. Mijn keukenkastjes zijn weer als nieuw',
  },
];
