import { SmartFormElement } from '@sal-solution/ui-web';

export const steps = ['User Information', 'Measurement'];

export const userInformationStepsNL: SmartFormElement[] = [
  {
    name: 'name',
    label: 'Uw naam',
    placeholder: 'Jan Jansen',
    type: 'input',
    required: true,
  },
  {
    name: 'town',
    label: 'Uw woonplaats',
    placeholder: 'Amsterdam',
    type: 'input',
    required: true,
  },
  {
    name: 'email',
    label: 'Uw email',
    placeholder: 'voorbeeld@test.nl',
    type: 'input',
    required: true,
  },
  {
    name: 'phone',
    label: 'Uw telefoonnummer',
    placeholder: '0612345678',
    type: 'input',
    required: true,
  },
];

export const imageStepsNL: SmartFormElement[] = [
  {
    name: 'images',
    label: "Foto's van uw keuken (optioneel)",
    type: 'images',
    placeholder: 'Upload een foto',
  },
  // {
  //   name: 'kitchenImage2',
  //   label: 'Foto van uw keuken (2/3) (optioneel)',
  //   type: 'image',
  //   placeholder: 'Upload een foto',
  // },
  // {
  //   name: 'kitchenImage3',
  //   label: 'Foto van uw keuken (3/3) (optioneel)',
  //   type: 'image',
  //   placeholder: 'Upload een foto',
  // },
];

export const kitchenInformationStepsNL: SmartFormElement[] = [
  {
    label: 'Wat wilt u laten wrappen?',
    type: 'checkmark',
    name: 'kitchenWrap',
    required: true,
    checkMarks: [
      {
        name: 'kitchenCabinets',
        label: 'Keukenkasten',
      },
      {
        name: 'kitchenIsland',
        label: 'Keukeneiland',
      },
      {
        name: 'kitchenCountertop',
        label: 'Aanrechtblad',
      },
      {
        name: 'kitchenRest',
        label: 'Overig',
      },
    ],
  },
  // {
  //   name: 'kitchenDate',
  //   label: 'Wanneer wilt u de keuken laten wrappen?',
  //   type: 'checkmark',
  //   required: true,
  //   checkMarks: [
  //     {
  //       name: 'kitchenDate1',
  //       label: 'Zo snel mogelijk',
  //     },
  //     {
  //       name: 'kitchenDate2',
  //       label: 'Binnen enkele weken',
  //     },
  //     {
  //       name: 'kitchenDate3',
  //       label: 'Geen voorkeur',
  //     },
  //   ],
  // },
  // {
  //   name: 'kitchenColor',
  //   label: 'Welke kleur wilt u de keuken laten wrappen?',
  //   type: 'input',
  //   placeholder: 'Zwart, roze, /web-link-naar-kleuren, etc.',
  // },
  // {
  //   name: 'kitchenSize',
  //   label: 'Hoe veel keuken kasten wilt u laten wrappen?',
  //   placeholder: '10',
  //   type: 'input',
  //   required: true,
  // },
  ...imageStepsNL,
] as const;

export const userInformationStepsEN: SmartFormElement[] = [
  {
    name: 'name',
    label: 'Your name',
    placeholder: 'John Doe',
    type: 'input',
    required: true,
  },
  {
    name: 'town',
    label: 'Your town',
    placeholder: 'Amsterdam',
    type: 'input',
    required: true,
  },
  {
    name: 'email',
    label: 'Your email',
    placeholder: 'example@email.com',
    type: 'input',
    required: true,
  },
  {
    name: 'phone',
    label: 'Your phone number',
    placeholder: '0612345678',
    type: 'input',
    required: true,
  },
];

export const imageStepsEN: SmartFormElement[] = [
  {
    name: 'images',
    label: "Photo's of your kitchen (optional)",
    type: 'images',
    placeholder: 'Upload a photo',
  },
  // {
  //   name: 'kitchenImage2',
  //   label: 'Photo of your kitchen (2/3) (optional)',
  //   type: 'image',
  //   placeholder: 'Upload a photo',
  // },
  // {
  //   name: 'kitchenImage3',
  //   label: 'Photo of your kitchen (3/3) (optional)',
  //   type: 'image',
  //   placeholder: 'Upload a photo',
  // },
];

export const kitchenInformationStepsEN: SmartFormElement[] = [
  {
    label: 'What do you want to wrap?',
    type: 'checkmark',
    name: 'kitchenWrap',
    checkMarks: [
      {
        name: 'kitchenCabinets',
        label: 'Kitchen cabinets',
      },
      {
        name: 'kitchenIsland',
        label: 'Kitchen island',
      },
      {
        name: 'kitchenCountertop',
        label: 'Kitchen countertop',
      },
      {
        name: 'kitchenRest',
        label: 'Other',
      },
    ],
  },
  // {
  //   name: 'kitchenDate',
  //   label: 'When do you want to wrap the kitchen?',
  //   type: 'checkmark',
  //   checkMarks: [
  //     {
  //       name: 'kitchenDate1',
  //       label: 'As soon as possible',
  //     },
  //     {
  //       name: 'kitchenDate2',
  //       label: 'Within a few weeks',
  //     },
  //     {
  //       name: 'kitchenDate3',
  //       label: 'No preference',
  //     },
  //   ],
  // },
  // {
  //   name: 'kitchenColor',
  //   label: 'What color do you want to wrap the kitchen?',
  //   type: 'input',
  //   placeholder: 'Black, pink, /link-to-colors, etc.',
  // },
  // {
  //   name: 'kitchenSize',
  //   label: 'How many kitchen cabinets do you want to wrap?',
  //   placeholder: '10',
  //   type: 'input',
  // },
  ...imageStepsEN,
];
