import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindOfferRepliesQueryVariables = Types.Exact<{
  offerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FindOfferRepliesQuery = { __typename?: 'Query', findOfferReplies?: { __typename?: 'OfferKitchenReplies', contractors?: Array<{ __typename?: 'ContractorReply', responseId?: string | null, name: string, amount: number, rating: number, commentsCount?: number | null, accepted?: boolean | null } | null> | null, offer?: { __typename?: 'OfferKitchen', wrapTargets?: Array<string | null> | null, kitchenSize: string, kitchenColor: string, kitchenDate: string, created_at: string } | null } | null };


export const FindOfferRepliesDocument = gql`
    query findOfferReplies($offerId: ID) {
  findOfferReplies(offerId: $offerId) {
    contractors {
      responseId
      name
      amount
      rating
      commentsCount
      accepted
    }
    offer {
      wrapTargets
      kitchenSize
      kitchenColor
      kitchenDate
      created_at
    }
  }
}
    `;

/**
 * __useFindOfferRepliesQuery__
 *
 * To run a query within a React component, call `useFindOfferRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOfferRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOfferRepliesQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useFindOfferRepliesQuery(baseOptions?: Apollo.QueryHookOptions<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>(FindOfferRepliesDocument, options);
      }
export function useFindOfferRepliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>(FindOfferRepliesDocument, options);
        }
export function useFindOfferRepliesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>(FindOfferRepliesDocument, options);
        }
export type FindOfferRepliesQueryHookResult = ReturnType<typeof useFindOfferRepliesQuery>;
export type FindOfferRepliesLazyQueryHookResult = ReturnType<typeof useFindOfferRepliesLazyQuery>;
export type FindOfferRepliesSuspenseQueryHookResult = ReturnType<typeof useFindOfferRepliesSuspenseQuery>;
export type FindOfferRepliesQueryResult = Apollo.QueryResult<FindOfferRepliesQuery, FindOfferRepliesQueryVariables>;