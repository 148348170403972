import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindOfferKitchenContractorQueryVariables = Types.Exact<{
  findOfferKitchenContractorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FindOfferKitchenContractorQuery = { __typename?: 'Query', findOfferKitchenContractor?: { __typename?: 'OfferKitchenContractor', _id: string, town: string, images?: Array<string | null> | null, wrapTargets?: Array<string | null> | null, kitchenSize: string, kitchenColor: string, kitchenDate: string, created_at: string } | null };


export const FindOfferKitchenContractorDocument = gql`
    query findOfferKitchenContractor($findOfferKitchenContractorId: ID) {
  findOfferKitchenContractor(id: $findOfferKitchenContractorId) {
    _id
    town
    images
    wrapTargets
    kitchenSize
    kitchenColor
    kitchenDate
    created_at
  }
}
    `;

/**
 * __useFindOfferKitchenContractorQuery__
 *
 * To run a query within a React component, call `useFindOfferKitchenContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOfferKitchenContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOfferKitchenContractorQuery({
 *   variables: {
 *      findOfferKitchenContractorId: // value for 'findOfferKitchenContractorId'
 *   },
 * });
 */
export function useFindOfferKitchenContractorQuery(baseOptions?: Apollo.QueryHookOptions<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>(FindOfferKitchenContractorDocument, options);
      }
export function useFindOfferKitchenContractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>(FindOfferKitchenContractorDocument, options);
        }
export function useFindOfferKitchenContractorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>(FindOfferKitchenContractorDocument, options);
        }
export type FindOfferKitchenContractorQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorQuery>;
export type FindOfferKitchenContractorLazyQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorLazyQuery>;
export type FindOfferKitchenContractorSuspenseQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorSuspenseQuery>;
export type FindOfferKitchenContractorQueryResult = Apollo.QueryResult<FindOfferKitchenContractorQuery, FindOfferKitchenContractorQueryVariables>;