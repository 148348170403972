'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useLocale } from '@/hooks/useLocal';
import { HeroSection, Text } from '@sal-solution/ui-web';
import { localeEN, localeNL } from '@sal-solution/utils';
import tw from 'twin.macro';
import { LeadForm } from './components/LeadForm/LeadForm';

export const QuickForm = () => {
  const copy = useLocale({
    [localeEN]: copyEN,
    [localeNL]: copyNL,
  });

  return (
    <HeroSection
      id="offerte-aanvragen"
      innerStyle={[tw`px-8 flex-col space-y-4`]}
    >
      <Text variant="title" tw="w-full text-2xl text-left lg:text-center">
        {copy?.title}
      </Text>
      <Text>{copy?.description}</Text>
      <LeadForm checkMarkVariant="primary" />
    </HeroSection>
  );
};

const copyEN = {
  title: 'Request a quote quickly?',
  description:
    'Fill in the form below and we will contact you as soon as possible.',
};

const copyNL = {
  title: 'Snel een vrijblijvende offerte aanvragen?',
  description:
    'Vul onderstaand formulier in en wij nemen zo snel mogelijk contact met je op.',
};
