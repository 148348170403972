import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommentOfferKitchenResponseMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OfferResponseInput>;
}>;


export type CommentOfferKitchenResponseMutation = { __typename?: 'Mutation', commentOfferKitchenResponse?: { __typename?: 'OfferKitchen', _id: string, created_at: string } | null };


export const CommentOfferKitchenResponseDocument = gql`
    mutation commentOfferKitchenResponse($input: OfferResponseInput) {
  commentOfferKitchenResponse(input: $input) {
    _id
    created_at
  }
}
    `;
export type CommentOfferKitchenResponseMutationFn = Apollo.MutationFunction<CommentOfferKitchenResponseMutation, CommentOfferKitchenResponseMutationVariables>;

/**
 * __useCommentOfferKitchenResponseMutation__
 *
 * To run a mutation, you first call `useCommentOfferKitchenResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentOfferKitchenResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentOfferKitchenResponseMutation, { data, loading, error }] = useCommentOfferKitchenResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentOfferKitchenResponseMutation(baseOptions?: Apollo.MutationHookOptions<CommentOfferKitchenResponseMutation, CommentOfferKitchenResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommentOfferKitchenResponseMutation, CommentOfferKitchenResponseMutationVariables>(CommentOfferKitchenResponseDocument, options);
      }
export type CommentOfferKitchenResponseMutationHookResult = ReturnType<typeof useCommentOfferKitchenResponseMutation>;
export type CommentOfferKitchenResponseMutationResult = Apollo.MutationResult<CommentOfferKitchenResponseMutation>;
export type CommentOfferKitchenResponseMutationOptions = Apollo.BaseMutationOptions<CommentOfferKitchenResponseMutation, CommentOfferKitchenResponseMutationVariables>;