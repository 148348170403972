import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindOfferResponseCommentsQueryVariables = Types.Exact<{
  contractorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  responseId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  offerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FindOfferResponseCommentsQuery = { __typename?: 'Query', findOfferResponseComments: Array<{ __typename?: 'OfferComment', _id: string, comment: string, userId?: string | null, contractorId?: string | null, sender: string, created_at: string } | null> };


export const FindOfferResponseCommentsDocument = gql`
    query findOfferResponseComments($contractorId: ID, $responseId: ID, $offerId: ID) {
  findOfferResponseComments(
    contractorId: $contractorId
    responseId: $responseId
    offerId: $offerId
  ) {
    _id
    comment
    userId
    contractorId
    sender
    created_at
  }
}
    `;

/**
 * __useFindOfferResponseCommentsQuery__
 *
 * To run a query within a React component, call `useFindOfferResponseCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOfferResponseCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOfferResponseCommentsQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      responseId: // value for 'responseId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useFindOfferResponseCommentsQuery(baseOptions?: Apollo.QueryHookOptions<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>(FindOfferResponseCommentsDocument, options);
      }
export function useFindOfferResponseCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>(FindOfferResponseCommentsDocument, options);
        }
export function useFindOfferResponseCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>(FindOfferResponseCommentsDocument, options);
        }
export type FindOfferResponseCommentsQueryHookResult = ReturnType<typeof useFindOfferResponseCommentsQuery>;
export type FindOfferResponseCommentsLazyQueryHookResult = ReturnType<typeof useFindOfferResponseCommentsLazyQuery>;
export type FindOfferResponseCommentsSuspenseQueryHookResult = ReturnType<typeof useFindOfferResponseCommentsSuspenseQuery>;
export type FindOfferResponseCommentsQueryResult = Apollo.QueryResult<FindOfferResponseCommentsQuery, FindOfferResponseCommentsQueryVariables>;