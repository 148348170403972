import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "next-mdx-import-source-file";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    br: "br",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Privacybeleid van Keukenwrapvergelijker"
      })
    }), "\n", _jsx(_components.p, {
      children: "Bij Keukenwrapvergelijker waarderen we het vertrouwen dat u in ons stelt en nemen we de bescherming van uw gegevens serieus. Deze pagina legt uit welke informatie we verzamelen tijdens uw bezoek aan onze website, de redenen voor deze verzameling, en hoe het bijdraagt aan uw ervaring. Dit geeft u een helder beeld van onze werkwijze."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Toepassingsgebied"
      }), "\nDit beleid is van kracht voor alle diensten die worden aangeboden via ", _jsx(_components.a, {
        href: "http://www.keukenwrapvergelijker.nl",
        children: "www.keukenwrapvergelijker.nl"
      }), ". We wijzen u erop dat Keukenwrapvergelijker niet aansprakelijk is voor het privacybeleid van externe sites die mogelijk gelinkt zijn. Met het gebruik van onze website accepteert u dit beleid."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Vertrouwelijkheid"
      }), "\nKeukenwrapvergelijker respecteert uw privacy volledig en zorgt ervoor dat uw persoonlijke informatie altijd vertrouwelijk blijft."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Informatieverzameling en Gebruik"
      }), "\nWanneer u zich inschrijft voor onze diensten, verzamelen we persoonlijke gegevens die noodzakelijk zijn om deze diensten te leveren. Deze informatie wordt bewaard op onze beveiligde servers of die van een door ons gecontracteerde derde partij. Wij koppelen deze gegevens niet aan andere persoonlijke informatie die we bezitten."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Communicatie"
      }), "\nBerichten die u naar ons stuurt, zoals e-mails, kunnen worden opgeslagen om uw vragen te kunnen beantwoorden en om aan uw verzoeken te voldoen. We bewaren deze gegevens op onze eigen servers of op die van een derde partij, altijd met behoud van vertrouwelijkheid."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Cookies en Website Analyse"
      }), "\nWij gebruiken cookies om beter te begrijpen hoe bezoekers onze site gebruiken. Deze cookies helpen ons om onze diensten aan te passen aan uw behoeften. De verzamelde informatie door de cookies wordt opgeslagen op onze beveiligde servers, mogelijk ook die van derden."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Doel van gegevensverzameling"
      }), "\nWij verzamelen gegevens uitsluitend voor de in dit beleid genoemde doeleinden, tenzij we specifiek uw goedkeuring hebben verkregen voor andere doeleinden."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Interne en Externe Deling van Informatie"
      }), "\nUw gegevens worden niet gedeeld met externe partijen zonder uw toestemming, hoewel ze intern gedeeld kunnen worden om onze diensten te verbeteren. We verplichten al onze medewerkers tot het respecteren van uw privacy."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Wijzigingen in Ons Beleid"
      }), "\nVeranderingen in onze website kunnen invloed hebben op dit privacybeleid. We raden u aan dit beleid regelmatig te herzien zodat u op de hoogte blijft van eventuele wijzigingen."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Controle over Uw Persoonsgegevens"
      }), "\nU heeft altijd de mogelijkheid om uw persoonsgegevens die bij ons geregistreerd staan in te zien, te wijzigen of te verwijderen."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Communicatievoorkeuren Wijzigen"
      }), "\nU kunt uw inschrijving voor onze nieuwsbrieven aanpassen of opzeggen via de link onderaan de emails. Voor andere communicatievoorkeuren kunt u direct contact met ons opnemen."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Cookies uitschakelen"
      }), "\nU kunt uw browser instellen om alle cookies te weigeren of om aan te geven wanneer een cookie wordt verstuurd. Bedenk wel dat sommige functies van onze en andere websites mogelijk niet goed werken zonder cookies."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Vragen?"
      }), "\nWij toetsen regelmatig of we aan dit privacybeleid voldoen. Heeft u vragen over ons beleid, neem dan gerust contact op via onderstaande gegevens:"]
    }), "\n", _jsxs(_components.p, {
      children: ["Keukenwrapvergelijker", _jsx(_components.br, {}), "\n", _jsx(_components.a, {
        href: "mailto:contact@keukenwrapvergelijker.nl",
        children: "contact@keukenwrapvergelijker.nl"
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
